.table ,.tableReport{
  width: 100%;
  table-layout: fixed;
  word-wrap: break-word;
}

.table thead,.tableReport thead {
  background-color: #4989c6;
  border: none;
}

.table thead tr th, .tableReport thead tr th {
  color: white;
  font-size: 8px;
  border: 0.5px solid #e8e8e8;
  padding-top: 5px;
  padding-bottom: 5px;
}
.expand.table thead tr th,.expand.tableReport thead tr th{
  font-size: 16px; 
}
.table thead tr th:first-child {
  width: 20%;
  padding-left: 1rem;
}
.table.largeTable thead tr th:first-child {
  width: 20px;
  padding-left: 1rem;
}
.table thead tr th:not(:first-child),.tableReport thead tr th {
  text-align: center;
}

.table tbody tr:nth-child(2n+1),.tableReport tbody tr:nth-child(2n+1){
  background-color: #f8f8f8;
}
.table tbody tr:nth-child(2n),.tableReport tbody tr:nth-child(2n  ){
  background-color: white;
}

.table tbody tr td ,.tableReport tbody tr td{
  font-size: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #8E8E8E;
  border: 0.5px solid #e8e8e8;
}
.expand.table tbody tr td,.expand.tableReport tbody tr td {
  font-size: 16px;
}
.table tbody tr td:first-child {
  width: 20%;
  padding-left: 1rem;
}
.table tbody tr td:not(:first-child),.tableReport tbody tr td {
  text-align: center;
}

.selected{
  background: transparent linear-gradient(to right ,rgba(77, 80, 92, 0.42),#4D505C00);
}
.selected td:first-child{
  font-weight: bold;
  color: #4D505C;
}

.table i:global(.fa-arrow-up),.tableReport i:global(.fa-arrow-up){
  color: #94C11F;
}

.table i:global( .fa-arrow-down),.tableReport i:global( .fa-arrow-down){
  color: #E97871;
}

.tableReport tbody tr td{
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}