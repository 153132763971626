@import url('https://fonts.googleapis.com/css?family=Poppins:thin,extra-light,light,regular,medium,semi-bold,bold,extra-bold,black');
body {
  margin: 0;
  font-family:'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color:#4D505C
}

h2{
  font-size: 24px;
  color: #4D505C;
}
p{
  font-size: 14px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ReactModal__Overlay {
  z-index: 1000;
}