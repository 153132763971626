.leftSideBar {
  height: 100vh;
  width: 310px;
  background-color: white;
  display: flex;
  flex-direction: column;
  filter: drop-shadow(7px 5px 30px #00000038);
  position: fixed;
  left: 0;
  top: 0;
}

.leftSideBar nav {
  background-color: #4d505c;
}

.leftSideBar nav a {
  color: #ffffff;
  opacity: 0.4;
  font-size: 16px;
}

.logo {
  height: 100px;
  display: flex;
  align-items: center;
}

.leftSideBar nav a.active {
  background-color: #94c11f;
  color: white;
  opacity: 1;
  font-weight: bold;
}

.rightSideBarContainer {
  position: fixed;
  top: 0;
  right: 0;
  width: 310px;
  bottom: 0;
  overflow: auto;
}

.rightSideBar {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  filter: drop-shadow(-7px -5px 30px #00000012);
}

.rightSideBar .login {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
}

.rightSideBar .selectLabel {
  font-size: 12px;
  color: #8e8e8e;
  font-weight: 600;
}

.rightSideBar .radio input[type="radio"]:checked+.radio-label:before {
  background-color: #94c11f;
  box-shadow: inset 0 0 0 4px #f4f4f4;
}

.rightSideBar .radio input[type="radio"]+.radio-label {
  color: #abafb5;
  font-weight: 400;
}

.rightSideBar .radio input[type="radio"]:checked+.radio-label {
  color: #4d505c;
  font-weight: bold;
}

.rightSideBar .buttons {
  font-size: 10px;
  border-radius: 5px;
}

.topMenu {
  height: 100px;
  background-color: #4d505c;
  display: flex;
  align-items: center;
}

.topMenu img {
  height: 40px;
  width: 40px;
}

.topMenu h5 {
  font-size: 18px;
}

.topMenu a {
  font-size: 18px;
  color: #bfc2c5c4;
}

.sectionHeader {
  font-size: 20px;
  font-weight: bold;
  color: #4d505c;
}

.main {
  margin-left: 310px;
  margin-right: 310px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
}

.analyticsCard {
  filter: drop-shadow(-7px -5px 30px #00000012);
  border-radius: 20px;
  border: 0;
}

.analyticsCard :global(.card-header) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.cardTitle {
  font-weight: 500;
  font-size: 14px;
  color: #4d505c;
  transition: all 1s;
}

.cardYear {
  font-weight: 500;
  font-size: 14px;
  color: #4d505c;
  transition: all 1s;
}

.quaterFirst {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 50%;
  z-index: 1;
  transition: all 1s;
}

.quaterSecond {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 50%;
  z-index: 1;
  transition: all 1s;
}

.quaterThird {
  position: absolute;
  top: 50%;
  left: 0;
  width: 50%;
  height: 50%;
  z-index: 1;
  transition: all 1s;
}

.quaterFourth {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  z-index: 1;
  transition: all 1s;
}

.quaterFirst :global(.axis),
.quaterSecond :global(.axis),
.quaterThird :global(.axis),
.quaterFourth :global(.axis),
.quaterFirst :global(.checkbox-container input ~ .label),
.quaterSecond :global(.checkbox-container input ~ .label),
.quaterThird :global(.checkbox-container input ~ .label),
.quaterFourth :global(.checkbox-container input ~ .label),
.quaterFirst :global(tspan),
.quaterSecond :global(tspan),
.quaterThird :global(tspan),
.quaterFourth :global(tspan) {
  font-size: 8px;
}

.sm9lg17 {
  font-size: 9px;
}

.sm17lg33 {
  font-size: 17px;
  line-height: 17px;
}

.sm7lg12 {
  font-size: 7px;
  line-height: 7px;
}

.sm12lg17 {
  font-size: 12px;
  line-height: 17px;
}

@media screen and (max-width: 1500px),
(max-height: 800px) {
  .quaterFirst {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    z-index: 1;
    transition: all 1s;
  }

  .quaterSecond {
    position: absolute;
    top: 500px;
    left: 0;
    width: 100%;
    height: 500px;
    z-index: 1;
    transition: all 1s;
  }

  .quaterThird {
    position: absolute;
    top: 1000px;
    left: 0;
    width: 100%;
    height: 500px;
    z-index: 1;
    transition: all 1s;
  }

  .quaterFourth {
    position: absolute;
    top: 1500px;
    left: 0;
    width: 100%;
    height: 500px;
    z-index: 1;
    transition: all 1s;
  }

  .quaterFirst .fullButton,
  .quaterSecond .fullButton,
  .quaterThird .fullButton,
  .quaterFourth .fullButton {
    display: none;
  }

  .reports .quaterThird {
    position: absolute;
    top: 300px;
    left: 0;
    width: 100%;
    height: 500px;
    z-index: 1;
    transition: all 1s;
  }

  .reports .quaterFourth {
    position: absolute;
    top: 800px;
    left: 0;
    width: 100%;
    height: 500px;
    z-index: 1;
    transition: all 1s;
  }
}

.halfFirst {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  z-index: 1;
  transition: all 1s;
}

.halfSecond {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 50%;
  z-index: 1;
  transition: all 0.5s;
}

@media screen and (max-width: 1500px),
(max-height: 800px) {
  .halfFirst {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    z-index: 1;
    transition: all 1s;
  }

  .halfSecond {
    position: absolute;
    top: 500px;
    left: 0;
    width: 100%;
    height: 500px;
    z-index: 1;
    transition: all 1s;
  }

  .reports .halfFirst {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    z-index: 1;
    transition: all 1s;
  }

  .halfFirst .fullButton,
  .halfSecond .fullButton {
    display: none;
  }
}

@media screen and (min-width: 1500px),
(min-height: 800px) {
  .full {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    transition: all 1s;
  }

  .full .cardTitle {
    font-size: 28px;
    transition: all 1s;
  }

  .full .cardYear {
    font-size: 28px;
    transition: all 1s;
  }

  .full :global(.axis),
  .full :global(.axis),
  .full :global(.axis),
  .full :global(.axis),
  .full :global(.checkbox-container input ~ .label),
  .full :global(.checkbox-container input ~ .label),
  .full :global(.checkbox-container input ~ .label),
  .full :global(.checkbox-container input ~ .label),
  .full :global(tspan),
  .full :global(tspan),
  .full :global(tspan),
  .full :global(tspan) {
    font-size: 16px;
  }

  .full .sm9lg17 {
    font-size: 17px;
  }

  .full .sm17lg33 {
    font-size: 33px;
    line-height: 33px;
  }

  .full .sm7lg12 {
    font-size: 12px;
    line-height: 12px;
  }

  .full .sm12lg17 {
    font-size: 17px;
    line-height: 17px;
  }
}

@media screen and (min-width: 1630px),
(max-width: 1500px) {
  .cardYear {
    display: none;
  }
}

.legend {
  font-size: 10px;
  color: #8e8e8e;
}

.legendCircle {
  display: inline-block;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-right: 0.3rem;
}

@media screen and (min-width: 1500px),
(min-height: 800px) {
  .legend {
    font-size: 16px;
  }

  .legendCircle {
    width: 33px;
    height: 33px;
  }
}

.baseMenuBusy {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.style-table {
  height: 125px;
  overflow-y: scroll;
}
.fulePanelCard {
  overflow-y: auto;
}

.highlight {
  border: 2px solid red;
  border-radius: 4px;
}