nav a {
  color: #4d505c;
  text-decoration: none;
}
nav a:hover {
  color: #8ab41e;
}
nav a.active {
  color: #8ab41e;
  border-bottom: 3px solid #8ab41e;
}
.menu button,
.menu a {
  color: #c0c7d1;
  width: 230px;
  height: 230px;
  text-decoration: none;
}

.menu button:hover,
.menu a:hover {
  background-color: #4d505c;
  color: white;
}
.menu a.active {
  background-color: #4d505c;
  color: white;
}
.menu .img-menu {
  width: 77px;
  height: 77px;
}

.fs-8 {
  font-size: 0.75rem;
}
.rounded-custom {
  border-radius: 1rem;
}
.card-header.rounded-custom-top {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.btn.rounded-custom-bottom {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.rounded-top-0 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.mainLinks .card {
  box-shadow: 0px 0px 20px #e7e7e866;
  border: 1px solid #e7e7e8;
}
.mainLinks .card .card-header {
  flex: 1;
}
.mainLinks .card-body {
  color: #e97871;
  background-color: white;
  flex: none;
}
.mainLinks .card-body:hover {
  background-color: #e97871;
  color: white;
}
.fa-upload {
  color: red;
}
.text-success {
  color: #94c11f !important;
}
.btn-radius {
  border-radius: 24px;
}
.btn-success {
  background-color: #94c11f4d;
  color: #8ab41e;
  border: none;
}
.btn-success-primary {
  background-color: #8ab41e;
  color: white;
}
.border-success {
  border-color: #8ab41e !important;
}

/*buttons*/
.btn-primary {
  background-color: #4989c6;
}
.text-danger {
  color: #e97871 !important;
}
.btn-danger {
  background-color: #e97871;
}
.btn-outline-danger {
  border-color: #e97871;
  color: #e97871;
}
.btn-nav {
  height: 80px;
  width: 169px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.btn-nav.btn-outline-danger {
  border-width: 3px;
}
.card-filter {
  filter: drop-shadow(0px 0px 20px #e7e7e866);
}

/*Checkboxes*/
.checkbox-container {
  position: relative;
  padding-left: 20px;
  margin-right: 0px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: transparent;
  border: 1px solid #abafb5;
}

.checkbox-container:hover input ~ .checkmark {
  background-color: transparent;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: transparent;
  border: 1px solid #abafb5;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container input ~ .label {
  color: #abafb5;
  line-height: 15px;
  vertical-align: top;
  font-size: 14px;
  font-weight: 400;
}

.checkbox-container input:checked ~ .label {
  color: #4d505c;
  font-size: 14px;
  font-weight: 400;
}

.checkbox-container .checkmark:after {
  left: 4px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid #94c11f;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.day-checkbox {
  width: 55px;
}

.w-20 {
  width: 20%;
}

/*table*/
thead {
  background-color: #4d505c;
  border: 1px solid #4d505c;
}
.table > :not(:first-child) {
  border-top: 0;
}

.table-striped > tbody > tr > * select {
  background-color: transparent;
}
.table > :not(caption) > * > * {
  padding: 1rem;
}
.table.reducePadding > :not(caption) > * > * {
  padding: 1rem 0.3rem;
}
th.date {
  width: 200px;
}
th button.btn {
  color: #37404d;
  font-size: 14px;
}

/*Radio buttons*/
.radio {
  margin: 0.5rem;
}
.radio input[type="radio"] {
  position: absolute;
  opacity: 0;
}
.radio input[type="radio"] + .radio-label:before {
  content: "";
  background: transparent;
  border-radius: 100%;
  border: 1px solid #e7e7e8;
  display: inline-block;
  width: 1.2em;
  height: 1.2em;
  position: relative;
  top: 0.1em;
  margin-right: 0.3em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}
.radio input[type="radio"]:checked + .radio-label:before {
  background-color: #4d505c;
  box-shadow: inset 0 0 0 4px #f4f4f4;
}
.radio input[type="radio"]:focus + .radio-label:before {
  outline: none;
  border: 1px solid #e7e7e8;
}
.radio input[type="radio"]:disabled + .radio-label:before {
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border: 1px solid #e7e7e8;
  background: #b4b4b4;
}
.radio input[type="radio"] + .radio-label:empty:before {
  margin-right: 0;
}

th .radio input[type="radio"] + .radio-label {
  color: #ffffff;
  font-weight: 400;
}
th .radio input[type="radio"]:checked + .radio-label {
  color: #ffffff;
  font-weight: bold;
}
.radio input[type="radio"] + .radio-label {
  color: #4d505c;
  font-weight: 400;
}
.radio input[type="radio"]:checked + .radio-label {
  color: #4d505c;
  font-weight: bold;
}

.table-settings-bordered {
  border-spacing: 0;
  border-collapse: collapse;
  border-style: hidden;
}

.table-settings-bordered td {
  border: 1px solid #e7e7e8;
  padding: 10px;
}
.w-55 {
  width: 1087px;
}

.table-striped > tbody > tr:nth-of-type(odd) > *,
.table-striped > tbody > tr:nth-of-type(even) > * {
  color: #37404d;
}

.text-muted {
  color: #abafb5 !important;
}

.bg-light-red {
  background-color: #e9787133 !important;
  color: #e97871 !important;
}

.fw-semibold {
  font-weight: 600;
}

.form-control::-webkit-input-placeholder,
.react-datepicker__input-container input::-webkit-input-placeholder {
  color: #e8e8e8 !important;
  font-size: 14px;
}

.form-control:-moz-placeholder,
.react-datepicker__input-container input:-moz-placeholder {
  color: #e8e8e8 !important;
  font-size: 14px;
}

.form-control::-moz-placeholder,
.react-datepicker__input-container input::-moz-placeholder {
  color: #e8e8e8 !important;
  font-size: 14px;
}

.select-placeholder {
  color: #e8e8e8;
  font-size: 14px;
  font-weight: normal;
}

.select input.form-control {
  border: 1px solid #e8e8e8;
}
input.form-control:focus {
  box-shadow: none;
  border-color: #94c11f;
}
.select__indicator-separator {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.bold__control {
  font-weight: bold;
}

th {
  min-width: 100px;
}
.unset {
  min-width: unset;
}
table textarea {
  border: 0px;
  resize: none;
}
table textarea:focus-visible {
  outline: none;
}
input.form-control {
  font-size: 14px;
  color: #37404d;
}

form label {
  font-size: 12px;
  color: #8e8e8e;
  font-weight: 600;
}

form input.form-control {
  height: 48px;
  border: 1px solid #e8e8e8;
  border-radius: 0;
}
.text-small {
  font-size: 8px;
}

/*Square flexes*/
.square {
  flex: 1 0 auto;
  height: auto;
}
.square:before {
  content: "";
  float: left;
  padding-top: 100%;
}

/*font sizes*/
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}

/*date*/
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}
input[type="date"].empty {
  color: #e7e7e8;
}

/*React-date picker*/
.react-datepicker__input-container input {
  width: 100%;
  height: 48px;
  border: 1px solid #e8e8e8;
  border-radius: 0;
  padding: 0.375rem 0.75rem;
}
.react-datepicker__input-container input:focus {
  border: 1px solid #94c11f;
}
.react-datepicker__input-container input:focus-visible {
  outline: none;
}

/*SVG*/
.recharts-legend-wrapper {
  display: flex;
  align-items: flex-end;
}
.recharts-wrapper {
  position: absolute !important;
}

/* animation */

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
.popup-content {
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.setting-menu-item-deactivated .card-body {
  color: white;
  background-color: gray;
  pointer-events: none;
}

.setting-menu-item-deactivated .card-body:hover {
  cursor: default;
  color: white;
  background-color: gray;
}

.button-disabled {
  opacity: 0.3 !important;
  cursor: not-allowed;
}
